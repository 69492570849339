import axios from 'axios'
import { useAuth } from 'context/authProvider'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { UrlResponse } from 'models/api/urlResponse'
import queryString from 'query-string'
import { ReactNode, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { css } from 'styled-components'
import 'twin.macro'
import tw from 'twin.macro'
import MobileMenu from './authenticated/mobileMenu/mobileMenu'
import SideBar from './authenticated/sideBar/sideBar'
import { TopBarAuth } from './authenticated/topBar/topBar'
import { Footer } from './footer'
import OnBoarding from './onBoarding/onBoarding'
import { TopBar } from './unauthenticated/topBar/topBar'

/** @jsxImportSource @emotion/react */

export function SharedComponentsLayout({ children }: { children?: ReactNode }) {
  const { user } = useAuth()
  const { pathname, search } = useLocation()
  const { data } = useQuery('profilePhoto', () => axios.get<UrlResponse>(`creator/profile-photo`), {
    enabled: user?.creatorStatus === 'active'
  })

  const isViewOnboardingSatatus =
    (user?.creatorStatus === 'detailsAdded' && user?.userType === 'creator') ||
    (user?.creatorStatus === 'pending' && user?.userType === 'creator')

  const pathnameAccept =
    pathname == '/profile' ||
    pathname == '/configure-payment' ||
    pathname == '/change-role' ||
    pathname == '/'

  const [isOpenMobileMenu, setOpenMobileMenu] = useState(false)
  const [isViewOnboarding, setViewOnboarding] = useState(false)

  const queryParams = queryString.parse(search, {
    arrayFormat: 'index',
    parseBooleans: true,
    parseNumbers: true
  })

  useEffect(() => {
    if (queryParams.q !== 'onBoarding' && isViewOnboardingSatatus && !pathnameAccept) {
      setViewOnboarding(true)
    }
  }, [queryParams.q, isViewOnboardingSatatus, pathname])
  useEffect(() => {
    if (isViewOnboardingSatatus) {
      setOpenMobileMenu(true)
    }
  }, [isViewOnboardingSatatus])

  useEffect(() => {
    if (queryParams.q === 'onBoarding' && !isViewOnboarding && user?.userType === 'creator') {
      setViewOnboarding(queryParams.q === 'onBoarding')
      document.body.style.overflowY = 'hidden'
      document.documentElement.style.overflow = 'hidden'
    } else if (isViewOnboarding && queryParams.q != 'onBoarding') {
      setViewOnboarding(false)
      document.body.style.overflowY = 'auto'
      document.documentElement.style.overflow = 'auto'
    }
  }, [queryParams.q, isViewOnboarding])

  useEffect(() => {
    const tagBody = document.querySelector('body')
    const tagHtml = document.querySelector('html')
    if (tagBody && tagHtml && isOpenMobileMenu === true) {
      tagBody.style.overflowY = 'hidden'
      tagHtml.style.overflow = 'hidden'
    } else if (tagBody && tagHtml && isOpenMobileMenu === false && queryParams.q != 'onBoarding') {
      tagBody.style.overflowY = 'auto'
      tagHtml.style.overflow = 'auto'
    }
  }, [isOpenMobileMenu])

  const handleToggleIsOpenMobileMenu = () => {
    setOpenMobileMenu((prev) => !prev)
  }

  const handleCloseMobileMenu = () => {
    setOpenMobileMenu(false)
  }

  const { isScreenXl } = useMediaQuery()
  return (
    <div tw="text-gray-100 h-screen">
      {!!user ? (
        <TopBarAuth
          handleCloseMobileMenu={handleCloseMobileMenu}
          isViewOnboarding={isViewOnboarding}
          profilePhoto={data?.data?.url}
          handleToggleIsOpenMobileMenu={handleToggleIsOpenMobileMenu}
          isOpenMobileMenu={isOpenMobileMenu}
        />
      ) : (
        <TopBar />
      )}
      <div tw="pt-14 xl:pt-16">
        <div
          css={[
            tw`grid relative`,
            css`
              min-height: calc(100vh - 120px);
            `
          ]}
        >
          {!!user &&
            user?.isEmailVerified &&
            (isScreenXl ? (
              <SideBar isViewOnboarding={isViewOnboarding} />
            ) : (
              <MobileMenu
                css={[isViewOnboarding && tw`pointer-events-none`]}
                pathname={pathname}
                profilePhoto={data?.data?.url}
                handleCloseMobileMenu={handleCloseMobileMenu}
                isOpenMobileMenu={isOpenMobileMenu}
              />
            ))}

          {isViewOnboarding && (
            <OnBoarding
              handleToggleIsOpenMobileMenu={handleToggleIsOpenMobileMenu}
              creatorStatus={user?.creatorStatus}
              isOpenMobileMenu={isOpenMobileMenu}
              setOpenMobileMenu={setOpenMobileMenu}
            />
          )}

          {children}
        </div>
        {!!user && <Footer />}
      </div>
    </div>
  )
}

export default SharedComponentsLayout
